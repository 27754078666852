import PouchDB from 'pouchdb-browser'
PouchDB.adapter('worker', require('worker-pouch'))

export default {
  data() {
    return {
      remoteEFormSurvey: null,
    }
  },
  methods: {
    async getEForm() {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .get(endpoint, config)
        .then(response => {
          if (response.data.status) {
            response.data.data.forEach(data => {
              data.formatted_created_at = this.$moment.unix(data.created_at).format('DD/MM/YYYY HH:mm:ss')
            })

            this.eForm = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async createEForm(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/create-eform`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          if (response.data.status) {
            this.$router.push({ name: 'e-form' }, () => {})
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    async getEFormById(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform-by-id`

      await this.$axios
        .post(endpoint, data)
        .then(response => {
          if (response.data.status) {
            if (response.data.data) {
              this.eForm = response.data.data
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    // eform survey
    async connectEFormSurvey() {
      const host = `${process.env.VUE_APP_COUCH_DB_DIRECT_URL}/eform_survey`
      const remote = new PouchDB(host, {
        fetch(url, opts) {
          opts.headers.set('Content-Type', 'application/json')

          return PouchDB.fetch(url, opts)
        },
      })

      this.remoteEFormSurvey = remote
    },
    async onCompleteSurvey(data) {
      const payload = {
        _id: this.$nanoid(),
        eform_survey_id: this.eFormSurveyId,
        ...data,
      }

      await this.remoteEFormSurvey
        .put(payload)
        .then(async response => {})
        .catch(error => {
          console.log(error)

          this.$dialog.message.error('Failed to complete survey')
        })
    },
    // eform analytics
    async getEFormAnalytics(data) {
      const endpoint = `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/e-form/get-eform-analytics`
      const { token } = this.$store.getters['auth/getUser']
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }

      await this.$axios
        .post(endpoint, data, config)
        .then(response => {
          if (response.data.status) {
            this.data = response.data.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
